<template>
  <div class="line-live-order-page" style="height: auto">
    <img class="love-girl" src="@/assets/website-use/love.png" alt="LoveGirl" />
    <img
      class="need-girl"
      src="@/assets/website-use/needThisCoolGuy.png"
      alt="NeedThisCoolGuy"
    />
    <p class="title">下單頁面</p>
    <div class="tw-container">
      <div class="mb-2 alert-primary p-3 text-dark">
        <p class="fw-bolder mb-0">
          商品名稱： <span class="text-break">{{ merchandiseInfo.name }}</span>
        </p>
      </div>
      <div class="table-responsive mb-3">
        <table class="table table-hover">
          <thead>
            <tr>
              <th style="min-width: 150px">款式</th>
              <th style="min-width: 150px">價格</th>
              <th style="min-width: 100px">數量</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="keyword in keywordList" :key="keyword.id">
              <td class="text-start text-break">
                {{ keyword.styles }}
              </td>
              <td class="text-start text-break">
                <pre>{{ keyword.price }}</pre>
              </td>
              <td>
                <input
                  onwheel="this.blur()"
                  class="form-control"
                  type="number"
                  min="0"
                  v-model="keyword.qty"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- 下單 -->
      <div class="text-center">
        <img
          class="addOneA"
          src="@/assets/website-use/addOneA.png"
          alt="addOneA"
        />
        <button
          id="SubmitBtn"
          class="d-none submit"
          @click="sendMessage"
          type="button"
        >
          下單下單
        </button>
        <img
          class="addOneB"
          src="@/assets/website-use/addOneB.png"
          alt="addOneB"
        />
      </div>
    </div>
  </div>
</template>

<script>
// api
import { lineLoginWithoutReg } from '../../../../methods/API/lineLoginWithoutReg'

export default {
  data() {
    return {
      serverToken: null,
      queueId: null,
      groupLinkId: null,
      storeId: null,
      lineUserId: null,
      merchandiseInfo: {},
      keywordList: [],
      liffReady: false,
    }
  },
  created() {
    this.initialization();
  },
  mounted() {
    this.initLineSdk()
  },
  methods: {
    initialization() {
      this.serverToken = null
      this.queueId = this.$route.query.queueId
      this.groupLinkId = this.$route.query.groupLinkId
      this.storeId = this.$route.query.storeId
    },
    async initLineSdk() {
      try {
        await liff.init({ liffId: process.env.VUE_APP_LIVEORDER_PAGE_LINE_LIFFID })
        console.log(`LIFF init, liff.isLoggedIn = ${liff.isLoggedIn()}`)
        this.liffReady = true
        const context = liff.getContext();
        console.log(context)
        if (context.type !== 'external') this.lineUserId = context.userId;
        console.log(`groupLinkId: ${this.groupLinkId}`)
        console.log(`lineUserId: ${this.lineUserId}`)
        this.lineLogin()
        $('#SubmitBtn').removeClass('d-none')
      } catch (err) {
        console.error(err)
        alert(err.message)
      }
    },
    // line 登入
    lineLogin() {
      const result = lineLoginWithoutReg(this.groupLinkId, this.lineUserId)
      result.then(res => {
        if (res.code === '200') {
          console.log(res)
          this.serverToken = res.data
          this.getKeywordList()
        }
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
      })
    },
    // 取資料
    async getKeywordList() {
      try {
        this.$methods.switchLoading('show')
        const getKeywordListApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
        const header = {
          authorization: this.serverToken,
        }
        const data = [{
          type: 61,
          ids: [this.queueId],
          methods: '{getMerchandise{getMerchandisePictureLinks},getLineLiveQueueStockLinks{getStock{getMerchandiseStyles{getMerchandiseDiscounts{getStoreVip}}}}}'
        }]
        const res = await new Promise((resolve, reject) => {
          $.ajax({
            async: true,
            contentType: 'application/json',
            data: JSON.stringify(data),
            error: reject,
            headers: header,
            success: resolve,
            type: 'POST',
            url: getKeywordListApi,
          })
        })
        console.log(JSON.stringify(res))
        if (parseInt(res.code, 10) !== 200) throw new Error(`res.code = ${res.code}`)
        this.sortOutKeyword(res.data[0].objects[0])
      } catch (err) {
        console.error(err)
        alert(err.message)
      } finally {
        this.$methods.switchLoading('hide')
      }
    },
    // 整理資料
    sortOutKeyword(liveInfo) {
      this.keywordList = []
      // 商品資訊
      this.merchandiseInfo = liveInfo.merchandise
      // 組 table 資訊
      liveInfo.lineLiveQueueStockLinks.forEach(item => {
        // 組款式，組價格
        let price = ''
        let styles = ''
        item.qty = 0
        item.stock.merchandiseStyles.forEach(style => {
          styles += `${style.name},`
          if (style.hierarchy === 0) {
            price += `單價: ${style.price}元`
            if (style.merchandiseDiscounts.length > 0) {
              style.merchandiseDiscounts.forEach(discount => {
                price += '\n'
                if (discount.minQuantity && discount.maxQuantity) {
                  price += `購買 ${discount.minQuantity}~${discount.maxQuantity}個，單價: ${discount.price}元`
                } else if (discount.minQuantity && !discount.maxQuantity) {
                  price += `購買 ${discount.minQuantity}個以上，單價: ${discount.price}元`
                }
                if (discount.storeVipId) price += `(限VIP: ${discount.storeVip.name}以上)`
              })
            }
          }
        })
        item.price = price
        item.styles = styles.substring(0, styles.length - 1)
      })
      this.keywordList = JSON.parse(JSON.stringify(liveInfo.lineLiveQueueStockLinks))
    },
    // 發送訊息
    async sendMessage() {
      try {
        if (!this.canSendMessages()) throw new Error('無法傳送訊息')
        for (const item of this.keywordList) {
          try {
            const quantity = parseInt(item.qty ? item.qty : 0, 10)
            if (quantity <= 0) continue
            alert(`${item.keyword} +${quantity}`)
            await liff.sendMessages([{
              type: 'text',
              text: `${item.keyword} +${quantity}`,
            }])
          } catch (err) {
            console.error(err)
            alert(err.message)
          }
        }
        alert('下單成功')
        await liff.closeWindow()
      } catch (err) {
        console.error(err)
        alert(err.message)
      }
    },
    canSendMessages () {
      if (!liff.isInClient()) return false
      const contextType = liff.getContext()?.type
      if (['utou', 'room', 'group', 'square_chat'].indexOf(contextType) < 0) return false
      return true
    },
  },
}
</script>